var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dt-menu" },
    [
      _c(
        "el-menu",
        {
          staticClass: "dt-menu",
          attrs: {
            mode: "vertical",
            "text-color": "#8a8a8a",
            "default-openeds": _vm.openeds,
            "default-active": _vm.currentPage,
            collapse: _vm.open,
            router: false,
          },
        },
        [
          _vm._l(_vm.routeList, function (menu) {
            return [
              menu.pages_count == 1 && menu.menu_uri
                ? [
                    _c(
                      "el-menu-item",
                      {
                        key: menu.id,
                        attrs: { index: menu.id + "" },
                        on: {
                          click: function ($event) {
                            return _vm.setPageId(menu)
                          },
                        },
                      },
                      [
                        menu.icon
                          ? _c("img", {
                              staticClass: "menu-icon",
                              attrs: { src: _vm.handleIcon(menu) },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "span",
                          { attrs: { slot: "title" }, slot: "title" },
                          [_vm._v(_vm._s(menu.menu_name))]
                        ),
                      ]
                    ),
                  ]
                : [
                    _c(
                      "el-submenu",
                      { key: menu.id, attrs: { index: menu.id.toString() } },
                      [
                        _c("template", { slot: "title" }, [
                          menu.icon
                            ? _c("img", {
                                staticClass: "menu-icon",
                                attrs: { src: _vm.handleIcon(menu) },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c("span", [_vm._v(_vm._s(menu.name))]),
                        ]),
                        _vm._v(" "),
                        _vm._l(menu.pages, function (page) {
                          return [
                            page.uri
                              ? _c(
                                  "el-menu-item",
                                  {
                                    key: page.uri,
                                    attrs: { index: page.uri },
                                    on: {
                                      click: function ($event) {
                                        return _vm.setPageId(page, menu)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        attrs: { slot: "title" },
                                        slot: "title",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(page.name) +
                                            "\n                "
                                        ),
                                        _c("el-badge", {
                                          staticClass: "item",
                                          attrs: {
                                            value: _vm.messageNum(page.name),
                                          },
                                        }),
                                        _vm._v(" "),
                                        page.name === "电销外呼"
                                          ? _c("span", {
                                              staticClass: "new-icon",
                                              staticStyle: {
                                                position: "relative",
                                                top: "-2px",
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        }),
                      ],
                      2
                    ),
                  ],
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }