// 设置路由时需注意meta中的permission字段和permissionOr字段和permissionAnd字段
// permission为字符串，permissionOr和permissionAnd为数组对象

// 如果不写上述三字段 则不进行权限判断 也就是说 如果不需要校验权限则不需要写上述字段

// permission类型，表示单一权限，通过与store中的permission对象进行对比，此路由的
// meta信息中的permission字段在store的permission数组中， 则代表有权限，页面将会被渲染

// permissionOr类型， 或关系， 表示多个权限只要命中一个就渲染页面

// permissionAnd类型， 且关系， 表示只有多个条件同时命中时才能渲染页面

// 所有需要进行权限控制的页面 都需要在meta中添加permission或permissionOr或permissionAnd字段
// 添加规则为 一级菜单名称加横线加页面的title 例如:< 智能获客-推荐线索 >

// 此三个页面取消路由懒加载否则会出现elementui样式错乱
import permissionCreate from '@/views/permission/create'
import permissionUpdate from '@/views/permission/update'
import permissionView from '@/views/permission/view'

export default [
  {
    path: '/dt-index',
    name: '数据看板',
    component: () => import('@/views/dtData/index'),
    meta: {
      title: '数据看板',
      icon: 'mykey',
      permission: '数据看板-数据看板',
      keepAlive: false
    }
  },

  /**
   * 外呼系统
   */
  {
    path: '/callsystem/callhistory',
    name: 'callhistory',
    component: () => import('@/views/callsystem/callhistory'),
    meta: {
      title: '通话记录',
      icon: 'waihu',
      permission: '电销外呼-通话记录',
      keepAlive: false
    }
  },

  {
    path: '/callsystem/help',
    name: 'callsystemHelp',
    component: () => import('@/views/callsystem/help'),
    meta: {
      title: '帮助中心',
      icon: 'help',
      keepAlive: false
    }
  },

  {
    path: '/callsystem/autocall',
    name: 'callsystemauto',
    component: () => import('@/views/callsystem/autoCallList'),
    meta: {
      title: '外呼任务',
      icon: 'task',
      keepAlive: false,
      permission: '电销外呼-外呼任务'
    }
  },

  {
    path: '/callsystem/call-index',
    name: 'callboard',
    component: () => import('@/views/callsystem/callboard'),
    meta: {
      title: '数据看板',
      icon: 'waihu',
      permission: '电销外呼-数据看板',
      keepAlive: false
    }
  },
  {
    path: '/callsystem/calltask',
    name: 'calltask',
    component: () => import('@/views/callsystem/calltask'),
    meta: {
      title: '外呼任务',
      icon: 'waihu',
      permission: '电销外呼-外呼任务',
      keepAlive: false
    }
  },
  /**
   * 智能获客
   */
  {
    path: '/package',
    name: 'package',
    component: () => import('@/views/mykey/index'),
    meta: {
      title: '推荐线索',
      icon: 'mykey',
      permission: '智能获客-推荐线索',
      keepAlive: true,
      url: 'https://yx.file.dtbird.cn/yingxiao-page/wx506196448480591872.html'
    }
  },
  {
    path: '/',
    name: 'package-package',
    component: () => import('@/views/mykey/index'),
    meta: {
      title: '推荐线索',
      icon: 'mykey',
      permission: '智能获客-推荐线索',
      keepAlive: true,
      url: ' https://yx.file.dtbird.cn/yingxiao-page/wx506196448480591872.html'
    }
  },
  {
    path: '/globalquery/globalquery',
    name: 'globalquery',
    component: () => import('@/views/globalquery/index'),
    meta: {
      title: '自助找客',
      icon: 'search',
      permission: '智能获客-自助找客',
      keepAlive: true,
      url: 'https://yx.file.dtbird.cn/yingxiao-page/wx506196364208635904.html'
    }
  },
  {
    path: '/querykp/querykp',
    name: 'querykp',
    component: () => import('@/views/querykp/index'),
    meta: {
      title: '精准查kp',
      icon: 'searchkp',
      permission: '智能获客-精准查kp',
      keepAlive: true,
      url: 'https://yx.file.dtbird.cn/yingxiao-page/wx506196207714959360.html'
    }
  },
  {
    path: '/collection/collection',
    name: 'collection',
    component: () => import('@/views/collect/index'),
    meta: {
      title: '线索培育',
      icon: 'collect',
      permission: '客户培育-线索培育',
      keepAlive: true,
      url: 'https://yx.file.dtbird.cn/yingxiao-page/wx506195977774825472.html'
    }
  },
  {
    path: '/market/market',
    name: 'Market',
    component: () => import('@/views/market/index'),
    meta: {
      title: '线索包市场',
      icon: 'market',
      permission: '智能获客-行业获客方案'
    }
  },
  /**
   * 客户转化
   */
  {
    path: '/customerpond/customerpond',
    name: 'customerpond',
    component: () => import('@/views/customerpond/index'),
    meta: {
      title: '客户公海',
      icon: 'customerpond',
      permission: '客户管理CRM-客户公海',
      keepAlive: true,
      url: 'https://yx.file.dtbird.cn/yingxiao-page/wx506196522984013824.html'
    }
  },
  {
    path: '/contract/contract',
    name: 'contract',
    component: () => import('@/views/contract/contractNew'),
    meta: {
      title: '合同管理',
      icon: 'contract',
      permission: '客户管理CRM-合同管理',
      keepAlive: true
    }
  },

  /**
   * 微营销管理
   */
  {
    path: '/landpage/landpage',
    name: 'landpage',
    component: () => import('@/views/landpage/landpage'),
    meta: {
      title: '微营销管理',
      icon: 'marketing',
      permission: '微营销管理-落地页管理',
      keepAlive: true,
      url: 'https://yx.file.dtbird.cn/yingxiao-page/wx506197133792116736.html'
    }
  },
  {
    path: '/miniMarket/landing-page',
    name: 'landing-page',
    component: () => import('@/views/miniMarket/landingPage/list'),
    meta: {
      title: '微营销管理',
      icon: 'marketing',
      permission: '微营销管理-落地页管理',
      keepAlive: false,
      url: 'https://yx.file.dtbird.cn/yingxiao-page/wx506197133792116736.html'
    }
  },
  {
    path: '/miniMarket/landing-page-create',
    name: 'landing-page-create',
    component: () => import('@/views/miniMarket/landingPage/create'),
    meta: {
      title: '新建落地页',
      icon: 'marketing',
      permission: '微营销管理-微站管理',
      keepAlive: false,
      url: 'https://yx.file.dtbird.cn/yingxiao-page/wx506197202754863104.html'
    }
  },
  {
    path: '/miniMarket/name-card',
    name: 'name-card',
    component: () => import('@/views/miniMarket/nameCard/list'),
    meta: {
      title: '微名片管理',
      icon: 'marketing',
      permission: '微营销管理-微站管理',
      keepAlive: false,
      url: 'https://yx.file.dtbird.cn/yingxiao-page/wx506197202754863104.html'
    }
  },
  {
    path: '/miniMarket/name-card-create',
    name: 'name-card1',
    component: () => import('@/views/miniMarket/nameCard/create'),
    meta: {
      title: '新建微名片',
      icon: 'marketing',
      permission: '微营销管理-微站管理',
      keepAlive: false,
      url: 'https://yx.file.dtbird.cn/yingxiao-page/wx506197202754863104.html'
    }
  },
  /**
   * 销售战略决策
   */
  {
    path: '/saledecision/saledecision',
    name: 'Saledecision',
    component: () => import('@/views/saledecision/index'),
    meta: {
      title: '外部市场分析',
      icon: 'strategy',
      permission: '外部市场分析-外部市场分析'
    }
  },
  /**
   * 系统管理
   */
  {
    path: '/user-center/user-center',
    name: 'UserCenter',
    component: () => import('@/views/usercenter/index'),
    meta: {
      title: '个人中心',
      icon: 'usercenter',
      permission: '系统管理-个人中心'
    }
  },
  {
    path: '/subaccount/subaccount',
    name: 'SubAccount',
    component: () => import('@/views/subaccount/index'),
    meta: {
      title: '账号管理',
      icon: 'subacc',
      permission: '系统管理-账号管理'
    }
  },
  {
    path: '/setfield/setfield',
    name: 'setfield',
    component: () => import('@/views/setfield/index'),
    meta: {
      title: '字段设置',
      icon: 'setfield',
      permission: '系统管理-字段设置'
    }
  },
  {
    path: '/userprofile/userprofile',
    name: 'userprofile',
    component: () => import('@/views/userprofile/index'),
    meta: {
      title: '客户画像设置',
      icon: 'permission',
      permission: '系统管理-客户画像设置'
    }
  },
  {
    path: '/permission/permission',
    name: 'permission',
    component: () => import('@/views/permission/list'),
    meta: {
      title: '角色与权限',
      icon: 'permission',
      permission: '系统管理-角色与权限'
    }
  },
  {
    path: '/permission/create',
    name: 'permission-create',
    component: permissionCreate,
    meta: {
      title: '新建角色',
      icon: 'permission',
      permission: '系统管理-角色与权限-新建角色'
    }
  },
  {
    path: '/permission/update',
    name: 'permission-update',
    component: permissionUpdate,
    meta: {
      title: '编辑角色',
      icon: 'permission-update',
      permission: '系统管理-角色与权限-编辑'
    }
  },
  {
    path: '/permission/view',
    name: 'permission-view',
    component: permissionView,
    meta: {
      title: '查看角色',
      icon: 'permission-view',
      permission: '系统管理-角色与权限-查看'
    }
  },

  /**
   * 线索培育页面
   */

  {
    path: '/collection/contact/:id',
    name: 'contact-breed',
    component: () => import('@/views/contactBreed/index.vue'),
    meta: {
      title: '联系人培育',
      icon: 'contact-breed',
      permissionOr: [
        '客户培育-公域潜客池',
        '客户培育-私域培育池',
        '客户培育-私域成交池'
      ],
      keepAlive: false,
      url: 'https://yx.file.dtbird.cn/yingxiao-page/wx506196121127747584.html'
    }
  },
  {
    path: '/dong-cha/list/:id',
    name: 'customerView',
    component: () => import('@/views/insight/dongcha/list'),
    meta: {
      title: '列表',
      keepAlive: false
    }
  },
  {
    path: '/insight/data/vc/:id',
    name: 'insightDataView',
    component: () => import('@/views/insight/dongcha/list'),
    meta: {
      title: '企业画像',
      keepAlive: false
    }
  },
  {
    path: '/bi/screen/edit',
    name: 'screenEdit',
    component: () => import('@/views/charts/grid-box/edit')
  },
  {
    path: '/bi/screen/view',
    name: 'biScreen',
    component: () => import('@/views/charts/grid-box/pre')
  },
  {
    path: '/bi/edit',
    name: 'FlexibleCharts',
    component: () => import('@/views/charts/flexible'),
    meta: {
      title: '自由模板'
    }
  },
  {
    path: '/bi/theme',
    name: 'ThemeCharts',
    component: () => import('@/views/charts/theme'),
    meta: {
      title: 'e-charts主题'
    }
  },
  {
    path: '/bi/view',
    name: 'FlexChartsPreview',
    component: () => import('@/views/charts/flexible/preview'),
    meta: {
      title: '领鸟BI'
    }
  },

  /**
   * 详情页面
   */
  {
    path: '/keydetail/keydetail',
    name: 'keydetail',
    component: () => import('@/views/mykey/keydetail'),
    meta: {
      title: '线索详情',
      icon: 'table',
      permissionOr: [
        '智能获客-推荐线索',
        '智能获客-自助找客',
        '智能获客-商机云图',
        '客户管理CRM-客户公海',
        '客户培育-联系人培育',
        '客户培育-线索培育',
        '投融资洞察-融资企业洞察',
        '行业图谱-行业图谱',
        '投资机构-投资机构',
        '开放平台-我的API',
        '开放平台-API调用记录',
        '开放平台-API文档',
        '榜单KA-榜单KA',
        '园区洞察-园区洞察'
      ]
    }
  },
  {
    path: '/booklist/booklist',
    name: 'booklist',
    component: () => import('@/views/collect/booklist'),
    meta: {
      title: '培育列表',
      icon: 'table',
      permissionOr: ['客户培育-线索培育'],
      url: 'https://yx.file.dtbird.cn/yingxiao-page/wx506195977774825472.html'
    }
  },
  {
    path: '/newlandpage/newlandpage',
    name: 'newlandpage',
    component: () => import('@/views/landpage/newlandpage'),
    meta: {
      title: '新建落地页',
      icon: 'land-page',
      permissionOr: ['微营销管理-落地页管理']
    }
  },
  {
    path: '/userstay/userstay',
    name: 'userstay',
    component: () => import('@/views/landpage/userstay'),
    meta: {
      title: '查看落地页',
      icon: 'land-page',
      permissionOr: ['微营销管理-落地页管理']
    }
  },
  {
    path: '/globalquery/query',
    name: 'companySearch',
    component: () => import('@/views/search/company-search/index'),
    meta: {
      title: '企业查询',
      icon: 'company-search',
      permission: '智能获客-企业查询',
      keepAlive: true
    }
  },
  {
    path: '/globalquery/query2',
    name: 'companySearchPlus',
    component: () => import('@/views/search/search-plus/index'),
    meta: {
      title: '高级查找',
      icon: 'company-search-plus',
      permission: '智能获客-高级查找',
      keepAlive: false,
      url: 'https://yx.file.dtbird.cn/yingxiao-page/wx566021444966809600.html'
    }
  },
  {
    path: '/weixin/manage',
    name: 'chatmanage',
    component: () => import('@/views/wechat/chatmanage'),
    meta: {
      title: '群聊管理',
      icon: 'company-search-plus',
      permission: '企业微信-群聊管理',
      keepAlive: true
    }
  },
  {
    path: '/weixin/words',
    name: 'wechatlog',
    component: () => import('@/views/wechat/log'),
    meta: {
      title: '',
      icon: 'company-search-plus',
      permission: '企业微信-敏感记录',
      keepAlive: true
    }
  },
  {
    path: '/globalquery/map',
    name: 'mapSearchCustomer',
    component: () => import('@/views/map/t-map'),
    meta: {
      title: '商机云图',
      icon: 'company-search-plus',
      permission: '智能获客-商机云图',
      keepAlive: true,
      url: 'https://yx.file.dtbird.cn/yingxiao-page/wx566021794771763200.html'
    }
  },
  {
    path: '/agent/agent',
    name: 'dailishang',
    component: () => import('@/views/proxy-manage/index'),
    meta: {
      title: '代理商',
      permission: '代理商管理-代理商管理'
    }
  },
  {
    path: '/contact/contact',
    name: 'contactManage',
    component: () => import('@/views/customerpond/contactManage/index'),
    meta: {
      title: '联系人管理',
      permission: '客户管理CRM-联系人管理'
    }
  },
  {
    path: '/chance/chance',
    name: 'chanceManage',
    component: () => import('@/views/customerpond/chanceManage/index'),
    meta: {
      title: '商机管理',
      permission: '客户管理CRM-商机管理'
    }
  },
  {
    path: '/follow-record/list',
    name: 'followRecord',
    component: () => import('@/views/followRecord/list'),
    meta: {
      title: '跟进记录',
      permission: '客户管理CRM-跟进记录'
    }
  },
  {
    path: '/after-sales/list',
    name: 'afterSales',
    component: () => import('@/views/afterSales/index'),
    meta: {
      title: '成交客户管理',
      permission: '客户成功系统-成交客户管理'
    }
  },
  {
    path: '/preview/list',
    name: 'afterSales',
    component: () => import('@/views/mykey/preview/list'),
    meta: {
      title: '预览'
    }
  },
  {
    path: '/robot-sales/list',
    name: 'afterSales',
    component: () => import('@/views/robot/index'),
    meta: {
      title: '销售机器人'
    }
  },
  {
    path: '/robot-monitor/monitoring',
    name: 'schema',
    component: () => import('@/views/insight/qy-monitor'),
    meta: {
      title: '企业事件监测'
    }
  },
  {
    path: '/insight/policy',
    name: 'industryPolicy',
    component: () => import('@/views/insight/schema/policy/policy'),
    meta: {
      title: '行业政策'
    }
  },
  {
    path: '/industry/policy/:id',
    name: 'PolicyDetail',
    component: () => import('@/views/insight/schema/policy/detail'),
    meta: {
      title: '行业政策详情'
    }
  },
  {
    path: '/robot-monitor/mine',
    name: 'robotMonitorMine',
    component: () => import('@/views/robot/monitor/mine'),
    meta: {
      title: '我的监控'
    }
  },
  {
    path: '/robot-monitor/log',
    name: 'robotMonitorLog',
    component: () => import('@/views/robot/monitor/log'),
    meta: {
      title: '监控日志'
    }
  },

  {
    path: '/pay-back/list',
    name: 'returnList',
    component: () => import('@/views/contract/payBack'),
    meta: {
      title: '收/退款管理',
      permission: '客户管理CRM-收/退款管理'
    }
  },

  {
    path: '/run-in/list',
    name: 'runinbee',
    component: () => import('@/views/runIn/customerpond/customerpond'),
    meta: {
      title: '数据下钻'
    }
  },

  {
    path: '/optimization-package/list',
    name: 'optimization',
    component: () => import('@/views/optimizationPackage/list'),
    meta: {
      title: '优化线索包'
    }
  },

  {
    path: '/supplier/supplier',
    name: 'supplier',
    component: () => import('@/views/supplier/supplier/list'),
    meta: {
      title: '供应商管理'
    }
  },

  {
    path: '/supplier/purchase',
    name: 'purchase',
    component: () => import('@/views/supplier/purchase/list'),
    meta: {
      title: '采购管理',
      permission: '供应商-采购管理'
    }
  },

  {
    path: '/huawei/tools',
    name: 'huawei',
    component: () => import('@/views/huawei/tools'),
    meta: {
      title: '华为云代理商工具包',
      permission: '华为云代理商工具包-常用工具'
    }
  },

  {
    path: '/target/sig',
    name: 'huawei',
    component: () => import('@/views/target/sign'),
    meta: {
      title: '签约目标设置',
      permission: '绩效目标设置-签约目标设置'
    }
  },
  {
    path: '/target/rec',
    name: 'targetSig',
    component: () => import('@/views/target/rec'),
    meta: {
      title: '收款目标设置',
      permission: '绩效目标设置-收款目标设置'
    }
  },
  {
    path: '/target/comprate',
    name: 'targetComprate',
    component: () => import('@/views/target/comprate'),
    meta: {
      title: '收款完成率',
      permission: '绩效目标设置-收款完成率'
    }
  },

  {
    path: '/analyze/pro',
    name: 'analyzePro',
    component: () => import('@/views/analyze/pro'),
    meta: {
      title: '销售过程分析',
      permission: '销售过程分析-触达过程分析'
    }
  },
  {
    path: '/analyze/rate',
    name: 'analyzeRate',
    component: () => import('@/views/analyze/rate'),
    meta: {
      title: '转化率分析',
      permission: '销售过程分析-转化率分析'
    }
  },
  {
    path: '/open/api',
    name: 'openAPI',
    component: () => import('@/views/openApi'),
    meta: {
      title: '开放平台-我的API',
      permission: '开放平台-我的API'
    }
  },

  {
    path: '/open/doc',
    name: 'document',
    redirect: '/globalsearch',
    component: () => import('@/views/openApi/document'),
    children: [
      {
        path: '/globalsearch',
        name: '企业查询',
        icon: 'search',
        component: () => import('@/views/openApi/components/globalsearch')
      },
      {
        path: '/business',
        name: '工商信息',
        icon: 'search',
        component: () => import('@/views/openApi/components/business')
      },
      {
        path: '/software',
        name: '软件著作权',
        icon: 'search',
        component: () => import('@/views/openApi/components/software')
      },
      {
        path: '/patent',
        name: '专利信息',
        icon: 'search',
        component: () => import('@/views/openApi/components/patent')
      },
      {
        path: '/touch',
        name: '触达信息',
        icon: 'search',
        component: () => import('@/views/openApi/components/touch')
      },
      {
        path: '/archive',
        name: '备案信息',
        icon: 'search',
        component: () => import('@/views/openApi/components/archive')
      },
      {
        path: '/staff',
        name: '主要人员',
        icon: 'search',
        component: () => import('@/views/openApi/components/staff')
      },
      {
        path: '/stockHolder',
        name: '股东信息',
        icon: 'search',
        component: () => import('@/views/openApi/components/stockHolder')
      },
      {
        path: '/branch',
        name: '分支机构',
        icon: 'search',
        component: () => import('@/views/openApi/components/branch')
      },
      {
        path: '/financeHis',
        name: '融资历程',
        icon: 'search',
        component: () => import('@/views/openApi/components/financeHis')
      },
      {
        path: '/beckonbid',
        name: '招投标',
        icon: 'search',
        component: () => import('@/views/openApi/components/beckonbid')
      },
      {
        path: '/ispHistory',
        name: '历史云服务商',
        icon: 'search',
        component: () => import('@/views/openApi/components/ispHistory')
      },
      {
        path: '/isp',
        name: '企业上云',
        icon: 'search',
        component: () => import('@/views/openApi/components/isp')
      },
      {
        path: '/ispincome',
        name: '企业上云（带营收指数）',
        icon: 'search',
        component: () => import('@/views/openApi/components/ispincome')
      },
      {
        path: '/watch',
        name: '重点企业云服务商变更监测',
        icon: 'search',
        component: () => import('@/views/openApi/components/watch')
      },
      {
        path: '/other',
        name: '其他',
        icon: 'search',
        component: () => import('@/views/openApi/components/other')
      }
    ],
    meta: {
      title: '开放平台-API文档',
      permission: '开放平台-API文档'
    }
  },

  {
    path: '/open/log',
    name: 'openLog',
    component: () => import('@/views/openApi/record'),
    meta: {
      title: '开放平台-API调用记录',
      permission: '开放平台-API调用记录'
    }
  },

  {
    path: '/report/contract',
    name: 'analyzeRate',
    component: () => import('@/views/warReport/contract'),
    meta: {
      title: '签约合同',
      permission: '月度季度战报-签约合同'
    }
  },

  {
    path: '/report/payment',
    name: 'payment',
    component: () => import('@/views/warReport/payBack'),
    meta: {
      title: '收/退款',
      permission: '月度季度战报-收/退款'
    }
  },

  {
    path: '/report/sign',
    name: 'payment',
    component: () => import('@/views/warReport/newOrOld'),
    meta: {
      title: '新签与复购',
      permission: '月度季度战报-新签与复购'
    }
  },

  {
    path: '/rank/contract',
    name: 'payment',
    component: () => import('@/views/saleRank/contract'),
    meta: {
      title: '销售排名',
      permission: '销售排名-签约合同排名'
    }
  },

  {
    path: '/rank/rement',
    name: 'payment',
    component: () => import('@/views/saleRank/rement'),
    meta: {
      title: '销售排名',
      permission: '销售排名-收款排名'
    }
  },

  {
    path: '/rank/performance',
    name: 'payment',
    component: () => import('@/views/saleRank/performance'),
    meta: {
      title: '销售排名',
      permission: '销售排名-业绩排名'
    }
  },

  {
    path: '/analyze/predict',
    name: 'predict',
    component: () => import('@/views/analyze/predict'),
    meta: {
      title: '业绩分析',
      permission: '销售过程分析-业绩预测'
    }
  },
  {
    path: '/app/market',
    name: 'AppStore',
    component: () => import('@/views/extendApp/appStore'),
    meta: {
      title: '应用市场',
      permission: '扩展应用-应用市场'
    }
  },

  {
    path: '/company/index',
    name: 'CompanyIntroduce',
    component: () => import('@/views/company')
  },
  {
    path: '/app/domain',
    name: 'predict',
    component: () => import('@/views/extendApp/searchDomain'),
    meta: {
      title: '查域名',
      permission: '扩展应用-查域名（专业版）'
    }
  },
  {
    path: '/app/pack',
    name: 'predict',
    component: () => import('@/views/extendApp/customerView'),
    meta: {
      title: '客群洞察',
      permission: '扩展应用-客群洞察'
    }
  },
  {
    path: '/insight/model',
    name: 'Model',
    component: () => import('@/views/insight/model/model'),
    meta: {
      title: '行业数据模型'
    }
  },
  {
    path: '/insight/model/booking',
    name: 'ModelBooking',
    component: () => import('@/views/insight/model/booking'),
    meta: {
      title: '数据融合'
    }
  },
  {
    path: '/insight/finance/:id',
    name: 'Finance',
    component: () => import('@/views/insight/finance'),
    meta: {
      title: '投融资洞察'
    }
  },
  {
    path: '/insight/techka/:id',
    name: 'TechKa',
    component: () => import('@/views/insight/tech-ka'),
    meta: {
      title: '科技KA'
    }
  },
  {
    path: '/insight/bd',
    name: 'band',
    component: () => import('@/views/insight/band/band'),
    meta: {
      title: '榜单KA'
    }
  },
  {
    path: '/insight/groups',
    name: 'qy-group',
    component: () => import('@/views/insight/qy-group'),
    meta: {
      title: '企业族群'
    }
  },
  {
    path: '/insight/schema/:id',
    name: 'schema',
    component: () => import('@/views/insight/schema'),
    meta: {
      title: '行业图谱'
    }
  },

  {
    path: '/insight/schema/detail/:id',
    name: 'schemaDetail',
    component: () => import('@/views/insight/schema/detail'),
    meta: {
      title: '行业图谱详情'
    }
  },
  {
    path: '/insight/schema/screen/:id',
    name: 'schemaScreen',
    component: () => import('@/views/insight/schema/screen'),
    meta: {
      title: '行业图谱数据大屏'
    }
  },
  {
    path: '/insight/beian',
    name: 'domainRecord',
    component: () => import('@/views/insight/record'),
    meta: {
      title: '新域名备案'
    }
  },
  {
    path: '/insight/vc/consultant',
    name: 'fa',
    component: () => import('@/views/insight/fa'),
    meta: {
      title: 'FA融资顾问'
    }
  },

  {
    path: '/insight/groups/detail',
    name: 'companyGroupDetail',
    component: () => import('@/views/insight/qy-group/detail'),
    meta: {
      title: '企业族群详情'
    }
  },
  {
    path: '/insight/band/detail',
    name: 'bandDetail',
    component: () => import('@/views/insight/band/detail/index'),
    meta: {
      title: '行业榜单详情'
    }
  },
  {
    path: '/insight/software',
    name: 'Software',
    component: () => import('@/views/insight/software/software'),
    meta: {
      title: '软件企业洞察'
    }
  },
  {
    path: '/insight/vc/:id',
    name: 'invest-group',
    component: () => import('@/views/insight/invest-group'),
    meta: {
      title: '投资机构'
    }
  },
  {
    path: '/insight/vc/event/:id',
    name: 'invest-event',
    component: () => import('@/views/insight/invest-event'),
    meta: {
      title: '投资事件'
    }
  },
  {
    path: '/insight/vc/:id/detail',
    name: 'investGroupDetail',
    component: () => import('@/views/insight/invest-group/detail'),
    meta: {
      title: '投资机构详情'
    }
  },
  {
    path: '/insight/park',
    name: 'ParkIndex',
    component: () => import('@/views/insight/park/index'),
    meta: {
      title: '园区洞察'
    }
  },
  {
    path: '/insight/park/detail',
    name: 'DarkDetail',
    component: () => import('@/views/insight/park/detail'),
    meta: {
      title: '园区洞察详情'
    }
  },
  {
    path: '/insight/overseas',
    name: 'OverseaDetail',
    component: () => import('@/views/insight/overseas'),
    meta: {
      title: '地域KA'
    }
  },
  {
    path: '/insight/chain',
    name: 'chain',
    component: () => import('@/views/insight/chain'),
    meta: {
      title: '连锁'
    }
  },
  {
    path: '/insight/shopping-center',
    name: 'shopCenter',
    component: () => import('@/views/insight/shop-center'),
    meta: {
      title: '购物中心'
    }
  },
  {
    path: '/insight/consumption',
    name: 'consumeScene',
    component: () => import('@/views/insight/consume'),
    meta: {
      title: '消费全景'
    }
  },
  {
    path: '/insight/site-rec/:id',
    name: 'siteRecommend',
    component: () => import('@/views/insight/site-recommend'),
    meta: {
      title: '选址推荐'
    }
  },
  {
    path: '/insight/location-ai',
    name: 'AiTool',
    component: () => import('@/views/insight/ai-addr'),
    meta: {
      title: '智能选址'
    }
  },
  {
    path: '/insight/site-ai',
    name: 'AiAddr',
    component: () => import('@/views/insight/cloud-choose'),
    meta: {
      title: 'AI罗盘'
    }
  },
  {
    path: '/insight/consumption/scene',
    name: 'consumeSceneMap',
    component: () => import('@/views/insight/consume/scene'),
    meta: {
      title: '消费全景地图'
    }
  },
  {
    path: '/insight/shopping-center/detail',
    name: 'shopCenterDetail',
    component: () => import('@/views/insight/shop-center/detail'),
    meta: {
      title: '购物中心详情'
    }
  },
  {
    path: '/insight/chain-radar',
    name: 'chainRadar',
    component: () => import('@/views/insight/chain/radar'),
    meta: {
      title: '连锁网络规划'
    }
  },
  {
    path: '/visual/temp',
    name: 'visualTemp',
    component: () => import('@/views/insight/visual/temp'),
    meta: {
      title: '可视化模板'
    }
  },
  {
    path: '/individual-stocks',
    name: 'PeTest',
    component: () => import('@/views/low-code/risk'),
    meta: {
      title: '个股查询'
    }
  },
  {
    path: '/peculiar/mock',
    name: 'PeMock',
    component: () => import('@/views/mock'),
    meta: {
      title: 'mock'
    }
  },
  {
    path: '/layout/view/:pageId',
    name: 'PeLayout',
    component: () => import('@/views/low-code/view'),
    hidden: true,
    meta: {
      title: '预览'
    }
  },
  {
    path: '/layout/edit/:pageId',
    name: 'PeLayout',
    component: () => import('@/views/low-code/edit'),
    hidden: true,
    meta: {
      title: '编辑'
    }
  },
  {
    path: '/individual-stocks/risk-inquiry',
    name: 'PeTest',
    component: () => import('@/views/low-code/stock'),
    meta: { title: '个股风险查询' }
  },
  {
    path: '/centralized-control/attention-pool',
    name: 'PePool',
    component: () => import('@/views/low-code/pool'),
    meta: { title: '关注池' }
  },
  {
    path: '/individual-stocks/concentration-inquiry',
    name: 'PeTest',
    component: () => import('@/views/low-code/crn'),
    meta: {
      title: '个股担保物集中度查询'
    }
  },
  {
    path: '/risk-home/risk-cockpit',
    name: 'PeFlex',
    component: () => import('@/views/low-code/car'),
    meta: {
      title: '风险驾驶舱'
    }
  },
  {
    path: '/stock/detail',
    name: 'StockDetail',
    component: () => import('@/views/low-code/detail'),
    meta: { title: '股票详情' }
  },

  {
    path: 'visual/case',
    name: 'visualCase',
    component: () => import('@/views/insight/visual/case'),
    meta: {
      title: '可视化案例'
    }
  },
  {
    path: '/visual/opus',
    name: 'visualOpus',
    component: () => import('@/views/insight/visual/work'),
    meta: {
      title: '可视化作品'
    }
  },
  {
    path: '/visual/cards',
    name: 'visualCards',
    component: () => import('@/views/charts/cell-libs'),
    meta: {
      title: '组件库'
    }
  },
  {
    path: '/insight/chain-ad',
    name: 'addrReserve',
    component: () => import('@/views/insight/chain/reserve'),
    meta: {
      title: '地址储备'
    }
  },
  {
    path: '/insight/chain-est',
    name: 'chainInvest',
    component: () => import('@/views/insight/chain/invest'),
    meta: {
      title: '连锁招商'
    }
  },
  {
    path: '/insight/chain-screen',
    name: 'radarScreen',
    component: () => import('@/views/insight/chain/screen'),
    meta: {
      title: '连锁雷达大屏'
    }
  },
  {
    path: '/ai/txt-optimize',
    name: 'textOptimize',
    component: () => import('@/views/ai/text'),
    meta: {
      title: 'AI文案'
    }
  },
  {
    path: '/cloud/customer',
    name: 'cloudProduce',
    component: () => import('@/views/cloud'),
    meta: {
      title: '云厂商'
    }
  },
  {
    path: '/insight/developers',
    name: 'InsightApplication',
    component: () => import('@/views/insight/application'),
    meta: {
      title: '应用开发者洞察'
    }
  },
  {
    path: '/insight/apps/market',
    name: 'InsightAppMarket',
    component: () => import('@/views/insight/market'),
    meta: {
      title: '应用上架监控'
    }
  },
  {
    path: '/insight/apps/rank',
    name: 'InsightAppRank',
    component: () => import('@/views/insight/rank'),
    meta: {
      title: '应用总排名监控'
    }
  },
  {
    path: '/insight/apps',
    name: 'InsightApps',
    component: () => import('@/views/insight/app-insight'),
    meta: {
      title: '移动应用洞察'
    }
  },
  {
    path: '/insight/messages',
    name: 'InsightMessages',
    component: () => import('@/views/insight/message-push'),
    meta: {
      title: '情报消息推送'
    }
  },
  {
    path: '/user/help',
    name: 'userManual',
    component: () => import('@/views/user/manual'),
    meta: {
      title: '用户手册'
    }
  }
]
