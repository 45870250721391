/*
 * @Author: Shy07
 * @Date: 2019-06-27 10:35:22
 * @Last Modified by: Shy07
 * @Last Modified time: 2019-06-27 13:57:57
 */
import http from './http'
import apiPath from './api_path'
import { userLogout } from '@/api/usercenter'
import { Message, MessageBox } from 'element-ui'
import Router from '../router'
import Store from '@/store/index'
import reBindWx from '@/views/login/dialog/reBindWx'
import { config } from '@/config'
// 退出登录
export const LoginOut = () => {
  userLogout({})
    .then(res => {
      if (res.code === 60000) {
        Store.dispatch('closePhoneSocket')
        Message({
          message: res.msg,
          type: 'success'
        })
        Store.commit('logout')
        Store.commit('clearAccount')
        localStorage.clear()
        if (
          !(Store.state.plugin.env === 'dd' || Store.state.plugin.env === 'tt')
        ) {
          Router.push({ path: '/login' })
        }
      } else {
        logoutFail()
      }
    })
    .catch(() => {
      logoutFail()
    })
}

function logoutFail() {
  MessageBox({
    title: '提示',
    message: '退出登录失败，是否强制退出?',
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    showCancelButton: true,
    type: 'warning'
  })
    .then(() => {
      Store.commit('logout')
      Router.push({ path: '/login' })
    })
    .catch(() => {})
}

export const BASE_REQUEST = async ({ api, opt, success, failure }) => {
  try {
    const codeTip = [
      67001,
      66003,
      66002,
      66001,
      65004,
      65003,
      61001,
      68001,
      69001,
      65002,
      65001,
      64000,
      63003,
      63002,
      63001,
      62001
    ]
    const res = await api(opt || {})
    if (res.code === 60000) {
      success && success(res)
    } else if (res.code === 64001) {
      success && success(res)
    } else if (res.code === 66004) {
      Store.dispatch('setGoBuyMeal', true)
    } else if (res.code === 61007 || res.code === 61008) {
      Store.dispatch('showModal', {
        title: '提示',
        size: '600px',
        view: reBindWx,
        options: {
          phone: res?.data || '',
          code: res?.code,
          title: res?.msg
        },
        onClose: () => {
          location.reload()
        }
      })
    } else {
      if (codeTip.indexOf(res.code) > -1) {
        if (res.code === 64000) {
          Store.dispatch('setExportLoad', false)
        }
        if (res.msg) {
          Message({
            message: `${res.msg} [错误编码${res.code}]`,
            type: 'error'
          })
        }
      }
      if (res.message == 500) {
        Message.error(`服务端接口错误,请稍后再试`)
      }
      failure && failure(res)
    }
    return res
  } catch (err) {
    console.log(err, 'err')
  }
}

//0.需要登录 1.开放浏览 2.临时密码
export const API = (apiName, isAuth = 0) => {
  const api = apiPath[apiName]
  const { url, method } = api
  if (!api) return null
  const func = async params => {
    try {
      //全局不需要进行token校验的接口
      if (config.noAuthList.includes(apiName) || isAuth == 1) {
        return await http[method](url, params)
      } else {
        const token = Store.state.permission.token
        const headers = {
          'X-Content-Type-Options': 'nosniff',
          Authorization: 'Bearer ' + (token || params.token)
        }
        return await http[method](url, params, headers)
      }
    } catch (err) {
      return await http[method](url, params)
    }
  }
  return func
}
